import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import NotFoundPage from './views/NotFound.vue'

Vue.use(Router)


export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home,
            meta: {
                title: 'Christliche Gemeinde Karlsruhe'
            }
        },
        {
            path: '/about',
            name: 'about',
            meta: {
                title: 'Über uns - Christliche Gemeinde Karlsruhe'
            },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
        },
        {
            path: '/gruppen',
            name: 'gruppen',
            meta: {
                title: 'Gruppen - Christliche Gemeinde Karlsruhe'
            },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "gruppen" */ './views/Gruppen.vue')
        },
        {
            path: '/veranstaltungen',
            name: 'veranstaltungen',
            meta: {
                title: 'Veranstaltungen - Christliche Gemeinde Karlsruhe'
            },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "veranstaltungen" */ './views/Veranstaltungen.vue')
        },
        {
            path: '/kontakt',
            name: 'kontakt',
            meta: {
                title: 'Kontakt - Christliche Gemeinde Karlsruhe'
            },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "kontakt" */ './views/Kontakt.vue')
        },
        {
            path: '/impressum',
            name: 'impressum',
            meta: {
                title: 'Impressum - Christliche Gemeinde Karlsruhe'
            },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "kontakt" */ './views/Impressum.vue')
        },
        {
            path: '/lpa',
            name: 'lpa',
            meta: {
                title: 'LPA - Christliche Gemeinde Karlsruhe'
            },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "kontakt" */ './views/lpa.vue')
        },
        {
            path: '/BAF',
            name: 'BAF-Anmeldung',
            meta: {
                title: 'BAF - Christliche Gemeinde Karlsruhe'
            },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "kontakt" */ './views/BAF.vue')
        },
        {
            path: '/Jugendtag',
            name: 'Jugendtag',
            meta: {
                title: 'Jugendtag - Christliche Gemeinde Karlsruhe'
            },
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () => import(/* webpackChunkName: "kontakt" */ './views/Jugendtag.vue')

        },         
        
        {
            path: '*',
            component: NotFoundPage,
        },
    ],
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})
